import request from '@/utils/requestV2'
const qs = require('qs')

// 确认调整
export function confirmChange (data) {
  return request({
    url: '/ooh-order/v1/cloudorder/confirmChange',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 废弃订单
export function discardOrder (data) {
  return request({
    url: '/ooh-order/v1/cloudorder/discard',
    method: 'POST',
    data: qs.stringify(data)
  })
}

// 销售采购的媒体商城订单
export function getMediaOrderPage (data) {
  return request({
    url: '/ooh-order/v1/cloudorder/getMediaOrderPage',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 更新媒体商城订单备注
export function updateRemark (data) {
  return request({
    url: '/ooh-order/v1/cloudorder/updateRemark',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 删除销售直采的广告商城订单
export function deleteMediaOrder (data) {
  return request({
    url: '/ooh-order/v1/cloudorder/deleteMediaOrder',
    method: 'POST',
    data: qs.stringify(data)
  })
}
