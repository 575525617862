<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col span="3">
        <Select size="small" v-model="orderStatus" placeholder="订单状态" clearable>
          <Option v-for="(status,index) in statuslist" :key="index" :value="status.value">{{status.name}}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select size="small" v-model="query.day" placeholder="创建时间" clearable>
          <Option value="14">14天内创建</Option>
          <Option value="7">7天内创建</Option>
          <Option value="3">3天内创建</Option>
          <Option value="0">当天创建</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select size="small" v-model="query.type" placeholder="发布类型" clearable>
          <Option v-for="(orderType,index) in orderTypeList" :key="index" :value="orderType.type">{{orderType.name}}
          </Option>
        </Select>
      </i-col>
      <i-col span="4">
        <DatePicker size="small" transfer :show-week-numbers="true" type="daterange" v-model="orderSchdule"
          @on-change="scheduleChange" style="width: 100%" placement="top-start" placeholder="订单档期"></DatePicker>
      </i-col>
      <i-col span="6">
        <i-input size="small" v-model='query.keyword' placeholder="客户名称/投放品牌/编号/备注"></i-input>
      </i-col>
      <i-col span="5">
        <Button size="small" type="primary" icon="ios-search" @click="SearchOrders">搜索</Button>
        <Button size="small" type="success" class="m-l-5" @click="handleCreateOrder">创建新订单</Button>
      </i-col>
    </Row>

    <Table size="small" stripe :columns="columndata" :data="tableData" :row-class-name="rowClassName"
      @on-sort-change="handleTableSort"></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber"
        @on-change="changePage"></Page>
    </div>

    <purchase-resource-list ref="purchaseResourceList"/>

    <Modal v-model="updateRemarkModal" width="400" footer-hide>
      <p slot="header" style="color:#f60;text-align:center">
          <Icon type="ios-information-circle"></Icon>
          <span>编辑订单备注</span>
      </p>

      <Row class="m-b-10">
        <i-col span="4" class="title">订单编号</i-col>
        <i-col span="20">{{ updateModel.code }}</i-col>
      </Row>
      <Row class="m-b-10">
        <i-col span="4" class="title">备注</i-col>
        <i-col span="20">
          <Input v-model="updateModel.remark" type="textarea" maxlength="100" show-word-limit :rows="4" placeholder="请输入订单描述备注信息..." />
        </i-col>
      </Row>
      <Row class="m-t-10">
        <i-col span="20" offset="4">
          <Button type="primary" @click="handleUpdateOrderRemark">保存并提交</Button>
        </i-col>
      </Row>
    </Modal>
  </div>
</template>

<script>
import purchaseResourceList from '@/components/order/common/PurchaseResourceList'

import { getOrderTypeList, getOrderStatus, getOrderPage, deleteOrder, updateOrderRemark } from '@/api/order/order'
import { discardOrder } from '@/api/order/cloudorder'

import { formatOrderStatus, formatOrderDifference } from '@/utils/tagStatus'
import { GetCurrentSchedule, GetDateStr, getTimeDifference } from '@/utils/dateFormat'

export default {
  components: { purchaseResourceList },
  data () {
    return {
      orderSchdule: '',
      statuslist: [],
      orderTypeList: [], // 订单发布类型
      orderStatus: '',
      query: {
        pageNumber: 1,
        pageSize: 15,
        userId: this.$store.getters.token.userInfo.userId,
        status: '',
        day: '',
        startDate: '',
        endDate: '',
        keyword: '',
        showProductQuantity: true,
        sort: ''
      },
      total: 0,
      tableData: [],
      columndata: [
        { title: '编号', key: 'code', width: 145 },
        { title: '客户名称', key: 'advertiserName' },
        { title: '投放品牌', key: 'brandName', width: 120 },
        { title: '发布类型', key: 'typeName', width: 80 },
        // {
        //   title: '投放品牌',
        //   render: (h, params) => {
        //     if (params.row.type === 2) {
        //       return h('strong', { attrs: { style: 'color:green;' } }, params.row.typeName)
        //     } else if (params.row.type === 3) {
        //       return h('strong', { attrs: { style: 'color:#19be6b;' } }, params.row.typeName)
        //     } else {
        //       return h('span', params.row.brandName)
        //     }
        //   }
        // },
        {
          title: '备注',
          render: (h, data) => {
            const remarkContent = [h('span', data.row.remark)]
            // 广告商城的订单，需要显示有赞编号
            if (data.row.type === 12) {
              if (data.row.remark.length) {
                remarkContent.push(h('span', '，'))
              }
              remarkContent.push(h('span', '有赞订单编号：' + (data.row.yzOrderId ? data.row.yzOrderId : '-')))
            }

            // 订单除已完成状态外， 都可以编辑备注
            if (data.row.status !== 4) {
              remarkContent.push(h('a', {
                style: {
                  marginLeft: '5px'
                },
                on: {
                  click: () => {
                    this.updateModel.orderId = data.row.id
                    this.updateModel.code = data.row.code
                    this.updateModel.remark = data.row.remark
                    this.updateRemarkModal = true
                  }
                }
              }, [h('Icon', { props: { type: 'md-create', size: 18 } })]
              ))
            }

            return h('div', remarkContent)
          }
        },
        // {
        //   title: '投放档期',
        //   key: 'startDate',
        //   sortable: 'custom',
        //   render: (h, params) => {
        //     return h('span', GetCurrentSchedule(params.row.startDate, params.row.endDate))
        //   }
        // },
        {
          title: '投放档期',
          render: (h, data) => {
            const spans = []
            data.row.purchasedScheduleList.forEach(item => {
              spans.push(h('span', GetCurrentSchedule(item.startDate, item.endDate)))
              spans.push(h('br'))
            })
            return h('div', spans)
          }
        },
        // { title: '档期天数', key: 'tradePublishday', width: 90, align: 'center' },
        {
          title: '采购资源统计',
          width: 120,
          render: (h, params) => {
            const spans = []
            params.row.orderProductQuantityBeanList.forEach(resource => {
              spans.push(h(
                'span',
                resource.productName
              ))
              spans.push(h(
                'span',
                {
                  attrs: {
                    class: 'text-orange text-12'
                  }
                },
                resource.quantity
              ))
              spans.push(h(
                'span',
                resource.unitName
              ))
              spans.push(h('br'))
            })
            return h('div', {
              attrs: {
                class: 'text-href'
              },
              on: {
                click: () => {
                  this.$refs.purchaseResourceList.showProductSkuList(params.row.id)
                }
              }
            }, spans)
          }
        },
        {
          title: '状态',
          align: 'center',
          width: 110,
          render: (h, params) => {
            return formatOrderStatus(h, params.row.status, params.row.statusName)
          }
        },
        {
          title: '保护倒计时',
          align: 'center',
          width: 110,
          render: (h, params) => {
            if (!params.row.fixed) {
              return h('span', {
                attrs: {
                  style: 'color:#19be6b;font-size:14px'
                }
              }, '不确定方案')
            } else {
              return formatOrderDifference(h, params.row.status, getTimeDifference(Date.now(), params.row.expireTime))
            }
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const buttonArray = [
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.handleShowDetail(params.row.id)
                  }
                }
              }, '详情')
            ]

            // 添加编辑、删除按钮
            const editableStatus = [0, 3] // 备选中、先签先得
            if (editableStatus.includes(params.row.status)) {
              buttonArray.push(h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.handleEdit(params.row.id)
                  }
                }
              }, '编辑'))

              // 媒体商城不允许删除
              if (params.row.type !== 12) {
                buttonArray.push(h('a', {
                  style: {
                    marginRight: '5px',
                    color: '#ef4f4f'
                  },
                  on: {
                    click: () => {
                      this.handleDelete(params.row.id)
                    }
                  }
                }, '删除'))
              }
            }

            // 媒体商城添加作废操作
            const discardStatus = [0, 1] // 备选中、预订中
            if (params.row.type === 12 && discardStatus.includes(params.row.status)) {
              buttonArray.push(h('a', {
                style: {
                  marginRight: '5px',
                  color: '#ef4f4f'
                },
                on: {
                  click: () => {
                    this.handleDiscard(params.row.id)
                  }
                }
              }, '作废'))
            }

            return h('div', buttonArray)
          }
        }
      ],

      updateRemarkModal: false,
      updateModel: {
        orderId: null,
        code: null,
        remark: null
      }
    }
  },
  created () {
    getOrderStatus().then(res => {
      this.statuslist = res
    })

    getOrderTypeList().then(res => {
      this.orderTypeList = res
    })
    this.initOrderList()
  },
  methods: {
    rowClassName (row, index) {
      return row.conflict ? 'table-conflict-row' : ''
    },
    initOrderList () {
      const state = []
      this.query.status = ''
      if (this.orderStatus !== undefined && this.orderStatus !== '') {
        state.push(this.orderStatus)
        this.query.status = JSON.stringify(state)
      }

      getOrderPage(this.query).then(response => {
        this.total = response.totalRow
        this.tableData = response.list
        this.query.pageNumber = response.pageNumber
      })
    },
    scheduleChange () {
      const shcedule = this.orderSchdule
      if (shcedule[0] === '' || shcedule[1] === '') {
        this.query.startDate = ''
        this.query.endDate = ''
      } else {
        this.query.startDate = GetDateStr(shcedule[0], 1)
        this.query.endDate = GetDateStr(shcedule[1], 1)
      }
    },
    SearchOrders () {
      this.query.pageNumber = 1
      this.initOrderList()
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initOrderList()
    },
    handleCreateOrder () {
      this.$store.commit('set_order_pageType', 'create')
    },
    handleShowDetail (orderId) {
      this.$store.commit('set_order_ID', orderId)
      this.$store.commit('set_order_pageType', 'detail')
      // 设置传递参数
      const query = { orderId: orderId }
      this.$store.dispatch('getOrderBaseInfo', query)
    },
    handleEdit (orderId) {
      this.$store.commit('set_order_ID', orderId)
      this.$store.commit('set_order_pageType', 'edit')
      // 设置传递参数
      const query = { orderId: orderId }
      this.$store.dispatch('getOrderBaseInfo', query)
    },
    handleDelete (orderId) {
      this.$Modal.confirm({
        title: '确定要删除',
        content: '<p>确定要删除当前订单？</p>',
        loading: true,
        onOk: () => {
          const data = {
            orderId: orderId
          }
          deleteOrder(data).then(res => {
            if (res.errcode === 0) {
              this.initOrderList()
              // 如果当前订单被选中，那么直接还原到初始化状态
              if (orderId === this.$store.state.order.orderId) {
                this.$store.commit('set_order_pageType', 'create')
              }
            }
          })
          this.$Modal.remove()
        }
      })
    },
    handleDiscard (orderId) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认要<b style="color:red"> 废弃 </b>当前订单吗？',
        onOk: () => {
          discardOrder({ orderId: orderId }).then(res => {
            this.initOrderList()
            this.$Notice.success({ desc: '订单废弃成功!' })
            // 如果当前订单被选中，那么直接还原到初始化状态
            if (orderId === this.$store.state.order.orderId) {
              this.$store.commit('set_order_pageType', 'create')
            }
          })
        }
      })
    },
    handleTableSort (params) {
      this.query.pageNumber = 1
      if (params.order === 'normal') { // 取消排序
        this.query.sort = ''
      } else {
        this.query.sort = JSON.stringify([
          {
            direction: params.order,
            property: params.key
          }
        ])
      }
      this.initOrderList()
    },
    handleUpdateOrderRemark () {
      updateOrderRemark(this.updateModel).then(res => {
        if (res.errcode === 0) {
          this.$Notice.success({ desc: '订单备注编辑成功！' })
          this.initOrderList()
          this.updateRemarkModal = false
        }
      })
    }
  }
}
</script>
