<template>
  <Modal v-model="ModalShow" width="800" title="采购单品明细" :footer-hide="true">
     <Table size="small" stripe :data="list" :columns="columns"></Table>
  </Modal>
</template>

<script>
import { getProductSkuList } from '@/api/order/orderitem'
import { GetCurrentSchedule } from '@/utils/dateFormat'

export default {
  data () {
    return {
      ModalShow: false,
      list: [],
      columns: [
        { title: '媒体类型', key: 'productName' },
        { title: '单品类型', key: 'productskuName' },
        {
          title: '采购数量',
          render: (h, data) => {
            return h('span', data.row.quantity + data.row.productUnit)
          }
        },
        {
          title: '采购档期',
          render: (h, data) => {
            return h('span', GetCurrentSchedule(data.row.startDate, data.row.endDate))
          }
        }
      ]
    }
  },
  methods: {
    showProductSkuList (orderId) {
      this.ModalShow = true

      getProductSkuList({ orderId: orderId }).then(res => {
        this.list = res
      })
    }
  }
}
</script>
